import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { emitEvent } from "../socket/socket";
import axios from "../utilities/axios";
import {
  addConversation,
  assignConversation,
  assignDepartments,
  changeLastMessageinConversation,
} from "./conversation";
import { isFound } from "../utilities/isFound";
import { serialize } from "object-to-formdata";
import soundFile from "../assets/sounds/sound-effect.mp3";
import { getLastFalseNoteObject } from "../utilities/getLastFalseNoteObject";

const initialState = {
  messages: [],
  page: 1,
  hasNextPage: null,
  status: "idle",
  statusSendMessage: "idle",
  statusResendMessage: "idle",
  createNoteInConversation: "idle",
  error: null,
  messagesStored: [],
  statusDeleteMessage: "idle",
  errorDeleteMessage: null,
  statusUpdateMessage: "idle",
  errorUpdateMessage: null,
  countPendingRequestsSendingMessageFromOperator: 0,
};

// send message by operator
export const sendMessageOperator = createAsyncThunk(
  "messages/sendMessageByOperator",
  async (data, thunkApi) => {
    try {
      let file;
      let response;
      if (data?.file) {
        data?.content.trim() !== ""
          ? (file = serialize({ file: data?.file, content: data?.content }))
          : (file = serialize({ file: data?.file }));
        response = await axios.post(
          `/websites/${data.websiteId}/messages/conversations/${data.conversation}/client`,
          file
        );
      } else {
        response = await axios.post(
          `/websites/${data.websiteId}/messages/conversations/${data.conversation}/client`,
          {
            content: data?.content,
          }
        );
      }
      if (response.status === 200) {
        const conversation = {
          ...data?.clientConversationStored,
          unread:
            data?.firstMessage ||
            (["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
              data?.not_assigned)
              ? 0
              : data?.clientConversationStored?.unread,
          assigned:
            data?.firstMessage ||
            (["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
              data?.not_assigned)
              ? {
                  ...data?.clientConversationStored?.assigned,
                  user: data?.user,
                }
              : data?.clientConversationStored?.assigned,
          lastMessage: {
            webSiteId: data.websiteId,
            conversation: data.conversation,
            content: data?.content,
            _id: response?.data?.data?._id,
            from: "OPERATOR",
            createdBy: data?.createdBy,
            seenByClient: false,
            createdAt: new Date(Date.now()),
            file: data?.file ? response.data.data.file : undefined,
            filetype: data?.file ? response.data.data.filetype : undefined,
          },
          status:
            data?.firstMessage ||
            (["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
              data?.not_assigned)
              ? "active"
              : data?.clientConversationStored?.status,
          id: data?.meta?.id,
          meta: data.meta,
          _id: data.conversation,
        };
        if (
          ["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
          data?.not_assigned &&
          data?.isFound
        ) {
          thunkApi.dispatch(
            assignConversation({
              user: data?.user,
              conversationId: data?.conversation,
            })
          );
        }
        data?.isFound
          ? (() => {
              const { clientConversationStored, ...message } = data;
              thunkApi.dispatch(changeLastMessageinConversation(message));
            })()
          : thunkApi.dispatch(addConversation(conversation));

        emitEvent("SEND_MESSAGE_FROM_OPERATOR", conversation);
        return {
          data: { ...data, _id: response.data.data?._id },
          messageId: data?._id,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const sendWhatsappMessage = createAsyncThunk(
  "messages/sendWhatsappMessage",
  async (data, thunkApi) => {
    try {
      let file;
      let response;
      if (data?.file) {
        data?.content.trim() !== ""
          ? (file = serialize({
              file: data?.file,
              content: data?.content,
              phone: data?.meta?.phone,
              conversation: data?.conversation,
            }))
          : (file = serialize({
              file: data?.file,
              phone: data?.meta?.phone,
              conversation: data?.conversation,
            }));
        response = await axios.post(
          `/websites/${data.websiteId}/messages/whats-app/client`,
          file
        );
      } else {
        response = await axios.post(
          `/websites/${data.websiteId}/messages/whats-app/client`,
          {
            content: data?.content,
            phone: data?.meta?.phone,
            conversation: data?.conversation,
          }
        );
      }
      if (response.status === 200) {
        const conversation = {
          ...data?.clientConversationStored,
          unread:
            data?.firstMessage ||
            (["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
              data?.not_assigned)
              ? 0
              : data?.clientConversationStored?.unread,
          assigned:
            data?.firstMessage ||
            (["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
              data?.not_assigned)
              ? {
                  ...data?.clientConversationStored?.assigned,
                  user: data?.user,
                }
              : data?.clientConversationStored?.assigned,
          lastMessage: {
            webSiteId: data.websiteId,
            conversation: data.conversation,
            content: data?.content,
            _id: response?.data?.data?._id,
            from: "OPERATOR",
            createdBy: data?.createdBy,
            seenByClient: false,
            createdAt: new Date(Date.now()),
            file: data?.file ? response.data.data.file : undefined,
            filetype: data?.file ? response.data.data.filetype : undefined,
          },
          status:
            data?.firstMessage ||
            (["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
              data?.not_assigned)
              ? "active"
              : data?.clientConversationStored?.status,
          id: data?.meta?.id,
          meta: data.meta,
          _id: data.conversation,
        };
        if (
          ["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
          data?.not_assigned &&
          data?.isFound
        ) {
          thunkApi.dispatch(
            assignConversation({
              user: data?.user,
              conversationId: data?.conversation,
            })
          );
        }
        data?.isFound
          ? (() => {
              const { clientConversationStored, ...message } = data;
              thunkApi.dispatch(changeLastMessageinConversation(message));
            })()
          : thunkApi.dispatch(addConversation(conversation));

        emitEvent("SEND_MESSAGE_FROM_OPERATOR", conversation);

        return {
          data: { ...data, _id: response.data.data?._id },
          messageId: data?._id,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createNote = createAsyncThunk(
  "messages/createNote",
  async (body) => {
    try {
      const response = axios.post(
        `/websites/${body.websiteId}/messages/conversations/${body.conversationId}/notes`,
        body
      );
      if (response.status === 200) {
        data = response.data.data;
      }
    } catch (err) {}
  }
);

export const resendMessageOperator = createAsyncThunk(
  "messages/resendMessageByOperator",
  async (data) => {
    const content = data?.content?.props?.dangerouslySetInnerHTML?.__html;
    try {
      let file;
      let response;
      if (data?.file) {
        content?.trim() !== ""
          ? (file = serialize({ file: data?.file, content: content }))
          : (file = serialize({ file: data?.file }));
        response = await axios.post(
          `/websites/${data.websiteId}/messages/conversations/${data.conversation}/client`,
          file
        );
      } else {
        response = await axios.post(
          `/websites/${data.websiteId}/messages/conversations/${data.conversation}/client`,
          {
            content,
          }
        );
      }
      if (response.status === 200) {
        if (
          ["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
          data?.not_assigned
        ) {
          thunkApi.dispatch(
            assignConversation({
              user: data?.user,
              conversationId: data?.conversation,
            })
          );
        }
        thunkApi.dispatch(changeLastMessageinConversation(data));
        emitEvent("SEND_MESSAGE_FROM_OPERATOR", {
          ...data?.clientConversationStored,
          assigned:
            ["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
            data?.not_assigned
              ? {
                  ...data?.clientConversationStored?.assigned,
                  user: data?.user,
                }
              : data?.clientConversationStored?.assigned,
          lastMessage: {
            webSiteId: data.websiteId,
            conversation: data.conversation,
            content: data?.content,
            _id: response?.data?.data?._id,
            from: "OPERATOR",
            createdBy: {
              firstName: data?.firstName,
              avatar: data?.createdBy?.avatar,
              availability: {
                status: data?.availability?.status,
              },
              _id: data?.createdBy?._id,
            },
            seenByClient: false,
            createdAt: new Date(Date.now()),
            file: data?.file ? response.data.data.file : undefined,
            filetype: data?.file ? response.data.data.filetype : undefined,
          },
          status:
            ["ADMIN", "SUPER-ADMIN"].includes(data?.user?.role?.code) &&
            data?.not_assigned
              ? "active"
              : data?.clientConversationStored?.status,
        });
        return {
          data: { ...data, _id: response.data.data?._id },
          messageId: data?._id,
        };
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const conversationMessages = createAsyncThunk(
  "messages/conversationMessages",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteId}/messages/conversations/${
          body.conversationId
        }/operator?limit=15&page=${body?.page ? body?.page : "1"}`
      );
      data = response.data.data.docs;
      if (response.status === 200) {
        return {
          data,
          hasNextPage: response.data.data.hasNextPage,
          page: body?.page,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createNoteInConversation = createAsyncThunk(
  "messages/createNoteInConversation",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteId}/messages/conversations/${body.conversation}/notes`,
        {
          content: body.content,
          mentionedUsers:
            body.mentionedUsers?.length > 0 ? body.mentionedUsers : undefined,
          departments:
            body.departments?.length > 0 ? body.departments : undefined,
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        let isNotAssigned = false;
        let departments =
          body?.assignedDepartments?.length > 0
            ? [...body?.assignedDepartments]
            : [];
        data?.departments?.length > 0 &&
          data?.departments?.forEach((element) => {
            if (!departments.includes(element)) {
              departments.push(element);
              isNotAssigned = true;
            }
          });
        isNotAssigned &&
          thunkAPI.dispatch(
            assignDepartments({
              departments,
              conversationId: body?.conversation,
            })
          );
        emitEvent("SEND_MESSAGE_FROM_OPERATOR", {
          _id: data?.conversation,
          lastMessage: {
            ...data,
            webSiteId: body.websiteId,
            from: "OPERATOR",
          },
        });
        return {
          data: { ...data, _id: response.data.data?._id },
          messageId: body?._id,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteMessage = createAsyncThunk(
  "messages/deleteMessage",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/messages/${body.messageId}`
      );
      data = {
        messageId: body.messageId,
        last_message: getLastFalseNoteObject(body?.messages),
      };

      if (response.status === 200) {
        thunkApi.dispatch(changeLastMessageinConversation(data.last_message));
        emitEvent("DELETE_MESSAGE_FROM_OPERATOR", {
          lastMessage: {
            webSiteId: body.websiteId,
            conversation: body.conversationId,
            message: { _id: body.messageId, conversation: body.conversationId },
            last_message: data.last_message,
          },
        });
        return {
          messageId: body.messageId,
          conversationId: body.messages[0]?.conversation,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateMessage = createAsyncThunk(
  "messages/updateMessage",
  async (body, thunkApi) => {
    let file;
    try {
      file = serialize({
        file: body?.editedFile,
        content: body?.editedMessage,
      });

      const response = await axios.patch(
        `/websites/messages/${body.messageId}`,
        file
      );

      if (response.status === 200) {
        thunkApi.dispatch(changeLastMessageinConversation(response.data.data));
        emitEvent("UPDATE_MESSAGE_FROM_OPERATOR", {
          lastMessage: {
            conversation: response.data.data.conversation,
            webSiteId: response.data.data.createdBy.websiteID,
            message: response.data.data,
          },
        });
        return {
          editedMessage: response.data.data,
          conversationId: body.conversationId,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message ? err.message : response.data.data?.message
      );
    }
  }
);
const slice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessagesStored: (state) => {
      state.messages = [];
      state.messagesStored = [];
      state.status = "idle";
    },
    messagesStoredSuccess: (state, action) => {
      state.messages.forEach((item) =>
        item._id === action.payload
          ? (() => {
              state.messagesStored = item.messages;
              state.hasNextPage = item?.hasNextPage;
              state.page = item?.page;
            })()
          : null
      );
    },
    addMessageToStateSuccess: (state, action) => {
      if (action.payload?.lastMessage?.from === "OPERATOR") {
        if (!isFound(state.messages, action.payload?._id)) {
          if (action.payload?.lastMessage) {
            state.messages.forEach((item) =>
              item._id === action.payload?._id
                ? (state.messagesStored = item.messages)
                : null
            );
          } else {
            state.messagesStored[0]?.conversation === action.payload?._id &&
              state.messages.forEach((item) =>
                item._id === action.payload?._id
                  ? (state.messagesStored = item.messages)
                  : null
              );
          }
        } else {
          state.messages.forEach((item) =>
            item._id === action.payload?._id
              ? (item.messages = [
                  ...item.messages,
                  action.payload?.lastMessage,
                ])
              : null
          );
          state.messagesStored[0]?.conversation === action.payload?._id &&
            state.messages.forEach((item) =>
              item._id === action.payload._id
                ? (state.messagesStored = item.messages)
                : null
            );
        }
      } else {
        if (!isFound(state.messages, action.payload?.conversation)) {
          if (action.payload?.lastMessage) {
            state.messages.forEach((item) =>
              item._id === action.payload?.conversation
                ? (state.messagesStored = item.messages)
                : null
            );
          } else {
            state.messagesStored[0]?.conversation ===
              action.payload?.conversation &&
              state.messages.forEach((item) =>
                item._id === action.payload?.conversation
                  ? (state.messagesStored = item.messages)
                  : null
              );
          }
        } else {
          state.messages.forEach((item) =>
            item._id === action.payload?.conversation
              ? (item.messages = [...item.messages, action.payload])
              : null
          );
          state.messagesStored[0]?.conversation ===
            action.payload?.conversation &&
            state.messages.forEach((item) =>
              item._id === action.payload.conversation
                ? (state.messagesStored = item.messages)
                : null
            );
        }
      }
    },
    addMessageFromOperatorSuccess: (state, action) => {
      state.messagesStored = [...state.messagesStored, action.payload];
      !isFound(state.messages, action.payload?.conversation)
        ? (state.messages = [
            ...state.messages,
            { _id: action.payload?.conversation, messages: [action.payload] },
          ])
        : (state.messages = state.messages.map((item) =>
            item._id === action.payload?.conversation
              ? { ...item, messages: [...item.messages, action.payload] }
              : item
          ));
    },
    resetStatusMessagesSuccess: (state) => {
      state.messages = [];
      state.messagesStored = [];
      state.status = "idle";
    },
    seenByClientSuccess: (state, action) => {
      state.messages = state.messages.map((item) =>
        item?._id === action.payload.conversation
          ? {
              ...item,
              messages: item?.messages?.map((el, index) =>
                index === item.messages.length - 1
                  ? { ...el, seenByClient: true }
                  : el
              ),
            }
          : item
      );
      action.payload?.conversation === state.messagesStored[0]?.conversation &&
        (state.messagesStored = state.messagesStored?.map((item, index) =>
          index === state.messagesStored.length - 1
            ? {
                ...item,
                seenByClient: true,
              }
            : item
        ));
    },
    deleteMessageFromSocketSuccess: (state, action) => {
      state.messages = state.messages.map((item) =>
        item?._id === action.payload?.conversation
          ? {
              ...item,
              messages: item?.messages?.filter(
                (el) => el?._id !== action.payload?.message?._id
              ),
            }
          : item
      );
      state.messagesStored[0]?.conversation === action.payload?.conversation &&
        (state.messagesStored = state.messagesStored.filter(
          (message) => message?._id !== action.payload?.message?._id
        ));
    },
    updateMessageFromSocketSuccess: (state, action) => {
      state.messages = state.messages.map((item) =>
        item?._id === action.payload?.conversation
          ? {
              ...item,
              messages: item?.messages?.map((el) =>
                el?._id === action.payload?.message?._id
                  ? action.payload?.message
                  : el
              ),
            }
          : item
      );
      state.messagesStored[0]?.conversation === action.payload?.conversation &&
        (state.messagesStored = state.messagesStored.map((message) =>
          message?._id === action.payload?.message?._id
            ? action.payload?.message
            : message
        ));
    },
  },
  extraReducers: {
    [conversationMessages.pending]: (state, action) => {
      action.meta.arg.page === 1 &&
        !action.meta.arg?.deleteConvEvent &&
        (state.status = "loading");
    },
    [conversationMessages.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.hasNextPage = action.payload?.hasNextPage;
      state.page = action.payload?.page + 1;
      isFound(state?.messages, action.payload.data[0]?.conversation) === false
        ? (state.messages = [
            ...state.messages,
            {
              _id: action.payload.data[0].conversation,
              messages: action.payload.data,
              hasNextPage: action.payload?.hasNextPage,
              page: action.payload?.page + 1,
            },
          ])
        : (state.messages = state.messages?.map((item) =>
            item?._id === action.payload.data[0].conversation
              ? {
                  ...item,
                  messages: action.payload.data
                    ?.concat(item?.messages)
                    ?.reduce((acc, current) => {
                      const x = acc.find((item) => item._id === current._id);

                      if (!x) {
                        return acc.concat([current]);
                      } else {
                        return acc;
                      }
                    }, []),
                  hasNextPage: action.payload?.hasNextPage,
                  page: action.payload?.page + 1,
                }
              : item
          ));
      state.messages.forEach((item) =>
        item._id === action.payload.data[0].conversation
          ? (state.messagesStored = item.messages)
          : null
      );
    },
    [conversationMessages.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },

    // send message by operator
    [createNoteInConversation.pending]: (state) => {
      state.createNoteInConversation = "loading";
    },
    [createNoteInConversation.fulfilled]: (state, action) => {
      state.createNoteInConversation = "succeeded ";

      state.messages = state.messages.map((item) =>
        item._id === action.payload?.data?.conversation
          ? {
              ...item,
              messages: item.messages.map((message) =>
                message._id === action.payload?.messageId
                  ? action.payload.data
                  : message
              ),
            }
          : item
      );
      state.messages.forEach(
        (item) =>
          item._id === action.payload?.data?.conversation &&
          (state.messagesStored = item.messages)
      );
    },
    [createNoteInConversation.rejected]: (state) => {
      state.createNoteInConversation = "error";
    },
    [sendMessageOperator.pending]: (state) => {
      state.countPendingRequestsSendingMessageFromOperator =
        state.countPendingRequestsSendingMessageFromOperator + 1;
      state.statusSendMessage = "loading";
    },

    [sendMessageOperator.fulfilled]: (state, action) => {
      state.countPendingRequestsSendingMessageFromOperator =
        state.countPendingRequestsSendingMessageFromOperator - 1;
      state.statusSendMessage = "succeeded";
      state.messages = state.messages.map((item) =>
        item._id === action.payload?.data?.conversation
          ? {
              ...item,
              messages: item.messages.map((message) =>
                message._id === action.payload?.messageId
                  ? action.payload.data
                  : message
              ),
            }
          : item
      );

      state.countPendingRequestsSendingMessageFromOperator === 0 &&
        state.messages.forEach(
          (item) =>
            item._id === action.payload?.data?.conversation &&
            (state.messagesStored = item.messages)
        );
    },
    [sendMessageOperator.rejected]: (state, action) => {
      state.statusSendMessage = "failed";
      state.countPendingRequestsSendingMessageFromOperator =
        state.countPendingRequestsSendingMessageFromOperator - 1;
      state.countPendingRequestsSendingMessageFromOperator === 0 &&
        (() => {
          state.messages = state.messages.map((item) =>
            item._id === action.meta.arg.conversation
              ? {
                  ...item,
                  messages: item.messages.map((message) =>
                    message?._id === action.meta.arg._id
                      ? { ...message, error: true }
                      : message
                  ),
                }
              : item
          );
          state.messagesStored = state.messagesStored.map((message) =>
            message._id === action.meta.arg._id
              ? { ...message, error: true }
              : message
          );
        })();

      state.error = action.error.message;
    },
    [sendWhatsappMessage.pending]: (state) => {
      state.statusSendMessage = "loading";
    },
    [sendWhatsappMessage.fulfilled]: (state) => {
      state.statusSendMessage = "succeeded";
    },
    [sendWhatsappMessage.rejected]: (state) => {
      state.statusSendMessage = "failed";
    },

    [resendMessageOperator.pending]: (state, action) => {
      state.countPendingRequestsSendingMessageFromOperator =
        state.countPendingRequestsSendingMessageFromOperator + 1;
      state.statusResendMessage = "loading";
      state.messagesStored = state.messagesStored.map((message) =>
        message?._id === action.meta.arg.messageId
          ? { ...message, status: "loading" }
          : message
      );
    },

    [resendMessageOperator.fulfilled]: (state, action) => {
      state.countPendingRequestsSendingMessageFromOperator =
        state.countPendingRequestsSendingMessageFromOperator - 1;
      state.statusResendMessage = "succeeded";
      state.messages = state.messages.map((item) =>
        item._id === action.payload?.data?.conversation
          ? {
              ...item,
              messages: item.messages.filter(
                (message) => message?._id !== action.payload?.messageId
              ),
            }
          : item
      );
      state.messages = state.messages.map((item) =>
        item._id === action.payload?.data?.conversation
          ? { ...item, messages: [...item.messages, action.payload?.data] }
          : item
      );

      state.countPendingRequestsSendingMessageFromOperator === 0 &&
        state.messages.forEach(
          (item) =>
            item._id === action.payload?.data?.conversation &&
            (state.messagesStored = item.messages)
        );
    },
    [resendMessageOperator.rejected]: (state, action) => {
      state.statusResendMessage = "failed";
      state.countPendingRequestsSendingMessageFromOperator =
        state.countPendingRequestsSendingMessageFromOperator - 1;
      state.error = action.error.message;
    },

    //
    [deleteMessage.pending]: (state) => {
      state.statusDeleteMessage = "loading";
    },
    [deleteMessage.fulfilled]: (state, action) => {
      state.statusDeleteMessage = "succeeded";
      state.messages = state.messages.map((item) =>
        item._id === action.payload.conversationId
          ? {
              ...item,
              messages: item.messages.filter(
                (message) => message._id !== action.payload.messageId
              ),
            }
          : item
      );
      state.messagesStored = state.messagesStored.filter(
        (message) => message._id !== action.payload.messageId
      );
    },
    [deleteMessage.rejected]: (state, action) => {
      state.statusDeleteMessage = "failed";
      state.errorDeleteMessage = action.payload;
    },
    [updateMessage.pending]: (state) => {
      state.statusUpdateMessage = "loading";
    },
    [updateMessage.fulfilled]: (state, action) => {
      state.statusUpdateMessage = "succeeded";
      state.messages = state.messages.map((item) =>
        item._id === action.payload.conversationId
          ? {
              ...item,
              messages: item.messages.map((message) =>
                message._id === action.payload.editedMessage._id
                  ? action.payload.editedMessage
                  : message
              ),
            }
          : item
      );
      state.messagesStored = state.messagesStored.map((message) =>
        message._id === action.payload.editedMessage._id
          ? action.payload.editedMessage
          : message
      );
    },
    [updateMessage.rejected]: (state, action) => {
      state.statusUpdateMessage = "failed";
      state.errorUpdateMessage = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const {
  addMessageToStateSuccess,
  addMessageFromOperatorSuccess,
  resetStatusMessagesSuccess,
  seenByClientSuccess,
  deleteMessageFromSocketSuccess,
  updateMessageFromSocketSuccess,
  resetMessagesStored,
} = slice.actions;
export default slice;
const { messagesStoredSuccess } = slice.actions;

export const storeMessages = (id) => async (dispatch) => {
  try {
    await dispatch(messagesStoredSuccess(id));
  } catch (error) {
    return console.error(error.message);
  }
};

export const addMessageToState = (data) => async (dispatch) => {
  try {
    const audio = new Audio(soundFile);
    dispatch(addMessageToStateSuccess(data));
    !data?.lastMessage?.isNote &&
      (() => {
        dispatch(
          data?.lastMessage
            ? addConversation(data)
            : changeLastMessageinConversation(data)
        );
        audio.play();
      })();
  } catch (error) {
    return console.error(error.message);
  }
};

export const addMessageFromOperator = (data) => async (dispatch) => {
  try {
    dispatch(addMessageFromOperatorSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const resetStatusMessages = () => async (dispatch) => {
  try {
    dispatch(resetStatusMessagesSuccess());
  } catch (error) {
    return console.error(error.message);
  }
};

export const seenByClient = (data) => async (dispatch) => {
  try {
    dispatch(seenByClientSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const deleteMessageFromSocket = (data) => async (dispatch) => {
  try {
    dispatch(deleteMessageFromSocketSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};
export const updateMessageFromSocket = (data) => async (dispatch) => {
  try {
    dispatch(updateMessageFromSocketSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};
